import type { SignupRegistrationType } from '~/types/Signup';

export class Signup {
    public uuid: string | null = null;

    public uniqueNumber: string | null = null;

    public loggedIn: boolean | null = null;

    public type: SignupRegistrationType | null = null;

    public wantsToUnregister: boolean | null = null;

    public relationData: string | null = null;

    public companyData: string | null = null;

    public isAccepted: boolean | null = null;

    public finished: boolean | null = null;

    public explanation: string | null = null;

    public vogPath: string | null = null;

    public svmnivoPath: string | null = null;

    public diplomas: string | null = null;
}
