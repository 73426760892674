import type { Relation } from '~/models/Relation';
import type { Signup } from '~/models/Signup';
import type { Step } from '~/types/Signup';

export type SignupState = {
    currentStepId: number;
    isLoading: boolean;
    relation: Relation | null;
    signup: Signup | null;
    steps: Step[];
};

export const defaultStateData = (): SignupState => ({
    currentStepId: 0,
    isLoading: false,
    relation: null,
    signup: null,
    // We have to add the steps to the store in pages/extranet/inschrijven/formulier.vue
    // Otherwise, Pinia is not loaded properly
    // when we try to add the steps in the store itself. For some reason the store
    // is not initialized yet when we try to add the steps in the store itself.
    steps: [],
});

export const DefaultState = (): SignupState => defaultStateData();

export type State = ReturnType<typeof DefaultState>;
