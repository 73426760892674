import { SET_CERTIFICATE } from './-mutation-types';
import { useCertificatesStore } from '~/stores/certificates';
import CertificateService from '~/services/api/CertificateService';
import type { Certificate } from '~/models/Certificate';
import SubscriptionService from '~/services/api/SubscriptionService';

const Actions = {
    async fetchCertificate(certificateId: number) {
        const certificatesStore = useCertificatesStore();

        const certificateService = new CertificateService();
        const response = await certificateService.fetchCertificate(certificateId);

        certificatesStore[SET_CERTIFICATE](response);
    },
    async fetchSubscription(subscriptionId: number) {
        const certificatesStore = useCertificatesStore();

        const subscriptionService = new SubscriptionService();
        const response = await subscriptionService.fetchSubscription(subscriptionId);

        certificatesStore[SET_CERTIFICATE](response);
    },
    setCertificate(certificate: Certificate | null) {
        const certificatesStore = useCertificatesStore();
        certificatesStore[SET_CERTIFICATE](certificate);
    },
};

export default Actions;
