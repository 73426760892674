import DateFormatUtils from '~/utils/DateFormatUtils';
import type { ReadCourseChamberDto, ReadPointsPerRegistrationTypeDto } from '~/types/Course';

export class Course {
    public approved: boolean | null = null;

    public chamberCodes: string[] = [];

    public chamberNames: string[] = [];

    public chambers: ReadCourseChamberDto[] = [];

    public code: string | null = null;

    public courseDate: string | null = null;

    public description: string | null = null;

    public id: number | null = null;

    public mainChamberId: number | null = null;

    public link: string | null = null;

    public name: string | null = null;

    public points: number | null = null;

    public registrationTypePoints: ReadPointsPerRegistrationTypeDto | null = null;

    public sev: boolean | null = null;

    public source: string | null = null;

    public trainingPartners: string[] = [];

    public year: number | null = null;

    public getApproved(): string {
        return this.approved ? 'Ja' : 'Nee';
    }

    public getCourseDate(): string {
        return DateFormatUtils.dateFormatToDisplay(this?.courseDate?.split(' ')[0] ?? '');
    }

    public getSevLabel(): string {
        return this.sev ? 'Ja' : 'Nee';
    }
}
