import routerOptions0 from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@rollup+wasm-node@4.24.0_@types+node@22.10.7_db0@0.2.1_e_ub6r4bn5qkwl4dkkfqaciembea/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/usr/local/jenkins/workspace/appvastgoedcertnl/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}