import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type { List } from '~/models/List';
import type { ListResponse } from '~/types/List';
import { ListFactory } from '~/models/factories/ListFactory';
import {
    type ReadRegistrationTypePointsDto,
    type ReadRegistrationTypePointsResponse,
    type RegistrationTypesOverview,
    type WriteRegistrationTypePointsDto,
} from '~/types/RegistrationType';

export default class RegistrationTypeService extends BaseApiService {
    private basePath = 'registration-types';

    /**
     * @description Fetch list of registration types.
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchRegistrationTypeList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(this.basePath)}/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchRegistrationTypes(
        queryParameters: (Record<string, unknown> | null) = null,
    ): Promise<RegistrationTypesOverview> {
        try {
            const response = await this.baseGet<RegistrationTypesOverview>(
                `${this.createPath('registration-type-point-requirements')}`,
                queryParameters,
            );

            return response;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchRegistrationType(id: number): Promise<ReadRegistrationTypePointsDto> {
        try {
            const response = await this.baseGet<ReadRegistrationTypePointsResponse>(
                `${this.createPath('registration-type-point-requirements')}/${id}`,
            );

            return response.data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async create(data: WriteRegistrationTypePointsDto): Promise<ReadRegistrationTypePointsDto> {
        try {
            const response = await this.basePost<ReadRegistrationTypePointsResponse>(
                `${this.createPath('registration-type-point-requirements')}`,
                data,
            );

            return response.data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async update(
        id: number,
        data: WriteRegistrationTypePointsDto,
    ): Promise<ReadRegistrationTypePointsDto> {
        try {
            const response = await this.basePost<ReadRegistrationTypePointsResponse>(
                `${this.createPath('registration-type-point-requirements')}/${id}`,
                data,
            );

            return response.data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async delete(id: number): Promise<void> {
        try {
            await this.baseDelete(`${this.createPath('registration-type-point-requirements')}/${id}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
