import { type FetchError } from 'ofetch';
import { LevelOfMeasurementsStatusTextFactory } from '~/models/factories/LevelOfMeasurementsStatusTextFactory';
import type { LevelOfMeasurementsStatusText } from '~/models/LevelOfMeasurementsStatusText';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type {
    LevelOfMeasurementsStatusTextResponse,
    LevelOfMeasurementsStatusTextsOverview,
    LevelOfMeasurementsStatusTextsOverviewResponse,
    WriteLevelOfMeasurementsStatusTextDto,
} from '~/types/LevelOfMeasurementsStatusText';

export default class LevelOfMeasurementsStatusTextService extends BaseApiService {
    private basePath = 'level-measurements-status-texts';

    /**
     * @description Fetch single level measurement status text by id.
     * @param {number} statusTextId Identifier of the resource to fetch
     * @returns {Promise<LevelOfMeasurementsStatusText>} Promise with the LevelOfMeasurementsStatusText as payload
     */
    async fetchStatusText(statusTextId: number): Promise<LevelOfMeasurementsStatusText> {
        try {
            const response = await this.baseGet<LevelOfMeasurementsStatusTextResponse>(
                `${this.createPath(this.basePath)}/${statusTextId}`,
            );

            return (new LevelOfMeasurementsStatusTextFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple status texts depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<LevelOfMeasurementsStatusTextsOverview>}
     * Promise with the LevelOfMeasurementsStatusTextsOverview as payload
     */
    async fetchStatusTexts(
        queryParameters: (Record<string, unknown> | null) = null,
    ): Promise<LevelOfMeasurementsStatusTextsOverview> {
        try {
            const response = await this.baseGet<LevelOfMeasurementsStatusTextsOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new LevelOfMeasurementsStatusTextFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a level measurement status text.
     * @param {number} statusTextId Id of the resource to save
     * @param {WriteLevelOfMeasurementsStatusTextDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(statusTextId: number, data: WriteLevelOfMeasurementsStatusTextDto): Promise<null> {
        try {
            return this.basePatch<null>(
                `${this.createPath(this.basePath)}/${statusTextId}`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
