import type { Document } from '~/models/Document';

export type DocumentState = {
    document: Document | null;
};

export const defaultStateData = (): DocumentState => ({
    document: null,
});

export const DefaultState = (): DocumentState => defaultStateData();

export type State = ReturnType<typeof DefaultState>;
