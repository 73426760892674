import { type FetchError } from 'ofetch';
import { CertificateFactory } from '~/models/factories/CertificateFactory';
import BaseApiService from '~/services/api/BaseApiService';
import type {
    ReadSubscriptionRegistrationPointsDto,
    SubscriptionPeriodResponse,
    SubscriptionRegistrationPointsResponse,
} from '~/types/Subscription';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';

export default class SubscriptionService extends BaseApiService {
    private basePath = 'subscriptions';

    async fetchSubscription(subscriptionId: number) {
        try {
            const response = await this.baseGet<SubscriptionPeriodResponse>(
                `${this.createPath(this.basePath)}/${subscriptionId}`,
            );

            const data = (new CertificateFactory()).toModel(response.data);

            return data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchPointsById(subscriptionId: number): Promise<ReadSubscriptionRegistrationPointsDto[]> {
        try {
            const response = await this.baseGet<SubscriptionRegistrationPointsResponse>(
                `${this.createPath(this.basePath)}/${subscriptionId}/points`,
            );

            return response.data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
