import { SubscriptionPeriod } from '~/models/SubscriptionPeriod';
import { BaseFactory } from '~/models/factories/BaseFactory';
import type { SubscriptionPeriodDto } from '~/types/Subscription';

export class SubscriptionPeriodFactory extends BaseFactory<SubscriptionPeriodDto, SubscriptionPeriod> {
    public toModel(dto: SubscriptionPeriodDto): SubscriptionPeriod {
        const model = new SubscriptionPeriod();

        model.endDate = dto.endDate;
        model.id = dto.id;
        model.levelMeasurementFinished = dto.levelMeasurementFinished;
        model.levelMeasurementRequired = dto.levelMeasurementRequired;
        model.startDate = dto.startDate;

        return model;
    }
}
