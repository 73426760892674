import { BaseFactory } from './BaseFactory';
import { CompanyFactory } from './CompanyFactory';
import { RemarkFactory } from './RemarkFactory';
import { TradeOrganisationFactory } from './TradeOrganisationFactory';
import { UserFactory } from './UserFactory';
import type { ReadRelationDto } from '~/types/Relation';
import { Relation } from '~/models/Relation';

export class RelationFactory extends BaseFactory<ReadRelationDto, Relation> {
    /* eslint-disable-next-line max-statements, max-lines-per-function, complexity */
    public toModel(dto: ReadRelationDto): Relation {
        const model = new Relation();

        model.accountNumber = dto.accountNumber;
        model.active = dto.active === 1;
        model.aNumber = dto.aNumber;
        model.city = dto.city;
        model.company = dto.company ?? null;
        model.companyId = dto.companyId ?? null;

        if (dto.companies) {
            model.companies = (new CompanyFactory()).toModels(dto.companies.data);
        }

        model.costCentre = dto.costCentre;
        model.dateOfBirth = dto.dateOfBirth;
        model.email = dto.email;
        model.emailBusiness = dto.emailBusiness;
        model.emailCorrespondence = dto.emailCorrespondence;
        model.emailInvoice = dto.emailInvoice;
        model.emailLogin = dto.emailLogin ?? '';

        model.firstName = dto.firstName;
        model.fullName = `${dto.initials}${dto.middleName ? ` ${dto.middleName}` : ''} ${dto.lastName}`;

        model.gender = dto.gender;
        model.houseNumber = dto.houseNumber;
        model.houseNumberPostfix = dto.houseNumberPostfix;
        model.id = Number(dto.id);
        model.initials = dto.initials;
        model.invoicePreferenceAddress = dto.invoicePreferenceAddress;
        model.isRegistration = dto.isRegistration;
        model.kNumber = dto.kNumber;
        model.lastName = dto.lastName;
        model.mandatoryMailOnly = dto.mandatoryMailOnly;
        model.middleName = dto.middleName;
        model.navigation = dto.navigation;
        model.noAnnualInvoice = dto.noAnnualInvoice;
        model.phone = dto.phone;
        model.phoneMobile = dto.phoneMobile;
        model.placeOfBirth = dto.placeOfBirth;
        model.postal = dto.postal;
        model.postfix = dto.postfix;
        model.prefix = dto.prefix;
        model.province = dto.province;
        model.registrationTypes = dto.registrationTypes ?? [];
        model.street = dto.street;

        if (dto.remarks) {
            model.remarks = (new RemarkFactory()).toModels(dto.remarks.data);
        }

        if (dto.tradeOrganisations) {
            model.tradeOrganisations = (new TradeOrganisationFactory()).toModels(dto.tradeOrganisations.data);
        }

        if (dto.user?.data?.id) {
            model.user = (new UserFactory()).toModel(dto.user.data);
        }

        model.vNumber = dto.vNumber;

        return model;
    }
}
