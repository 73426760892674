import validate from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@rollup+wasm-node@4.24.0_@types+node@22.10.7_db0@0.2.1_e_ub6r4bn5qkwl4dkkfqaciembea/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45setroutenamespace_45global from "/usr/local/jenkins/workspace/appvastgoedcertnl/middleware/01.setroutenamespace.global.ts";
import _02_45authorization_45global from "/usr/local/jenkins/workspace/appvastgoedcertnl/middleware/02.authorization.global.js";
import _03_45setbreadcrumbs_45global from "/usr/local/jenkins/workspace/appvastgoedcertnl/middleware/03.setbreadcrumbs.global.ts";
import _04_45setsaveurl_45global from "/usr/local/jenkins/workspace/appvastgoedcertnl/middleware/04.setsaveurl.global.ts";
import manifest_45route_45rule from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@rollup+wasm-node@4.24.0_@types+node@22.10.7_db0@0.2.1_e_ub6r4bn5qkwl4dkkfqaciembea/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45setroutenamespace_45global,
  _02_45authorization_45global,
  _03_45setbreadcrumbs_45global,
  _04_45setsaveurl_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.4_@rollup+wasm-node@4.24.0_magicast@0.3.5_next-auth@4.21.1_next@13.5._hmikjz473aj7qsr4iweip4rpwy/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}