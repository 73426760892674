import { type FetchError } from 'ofetch';

// eslint-disable-next-line @stylistic/max-len
export const apiErrorHandler = (fetchError: FetchError): FetchError | Error => ([422, 500].includes(fetchError?.statusCode || 0) ? fetchError : new Error(fetchError?.data?.message || 'Het lijkt erop dat er iets misgaat!'));

// eslint-disable-next-line max-statements, complexity
const parseErrors = (errors: Record<string, unknown>, messageContainer: string[]): void => {
    for (const errorField of Object.keys(errors)) {
        if (Array.isArray(errors[errorField])) {
            for (const errorMessage of errors[errorField]) {
                if (typeof errorMessage === 'string') {
                    messageContainer.push(`${errorField}: ${errorMessage}`);
                } else {
                    // eslint-disable-next-line max-depth
                    for (const deepErrorField of Object.keys(errorMessage)) {
                        // eslint-disable-next-line max-depth
                        if (Array.isArray(errorMessage[deepErrorField])) {
                            // eslint-disable-next-line max-depth
                            for (const deepErrorMessage of errorMessage[deepErrorField]) {
                                messageContainer.push(`${errorField}: ${deepErrorMessage}`);
                            }
                        } else if (typeof errorMessage[deepErrorField] === 'string') {
                            messageContainer.push(`${errorMessage[deepErrorField]}`);
                        } else {
                            messageContainer.push(<string>errorField);
                        }
                    }
                }
            }
        } else if (typeof errors[errorField] === 'object') {
            parseErrors(errors[errorField] as Record<string, unknown>, messageContainer);
        } else if (typeof errors[errorField] === 'string') {
            messageContainer.push(`${errors[errorField]}`);
        } else {
            messageContainer.push(<string>errorField);
        }
    }
};

export const handleErrors = (error: FetchError, messageContainer: string[]): void => {
    if ([406, 422, 424].includes(Number(error?.statusCode))) {
        const errors = error?.data || {};
        parseErrors(errors, messageContainer);
    } else {
        messageContainer.push('Het lijkt erop dat er iets misgaat!');
    }
};
