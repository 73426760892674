import { BaseFactory } from './BaseFactory';
import type { ReadChamberRegistrationTypeDto } from '~/types/Chamber';
import { ChamberRegistrationType } from '~/models/ChamberRegistrationType';

/* eslint-disable-next-line @stylistic/max-len */
export class ChamberRegistrationTypeFactory extends BaseFactory<ReadChamberRegistrationTypeDto, ChamberRegistrationType> {
    public toModel(dto: ReadChamberRegistrationTypeDto): ChamberRegistrationType {
        const model = new ChamberRegistrationType();

        model.durationInYears = Number(dto.durationInYears);
        model.id = Number(dto.id);
        model.name = dto.name;
        model.numberColumn = dto.numberColumn;
        model.numberPrefix = dto.numberPrefix;
        model.recertification = dto.recertification;
        model.type = dto.type;

        return model;
    }
}
