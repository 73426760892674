import type { Company } from './Company';
import type { Remark } from './Remark';
import type { TradeOrganisation } from './TradeOrganisation';
import type { User } from './User';
import type { Navigation } from './Navigation';
import type { ReadRegistrationTypeDto } from '~/types/Relation';
import type { ReadAddressDto } from '~/types/RelationAddress';
import DateFormatUtils from '~/utils/DateFormatUtils';
import { AddressTypeEnum } from '~/enums/AddressTypeEnum';

export class Relation {
    public accountNumber: string | null = null;

    public active: boolean | null = null;

    public aNumber: string | null = null;

    public city: string | null = null;

    public company: string | null = null;

    public companyId: number | null = null;

    public companies: Company[] = [];

    public costCentre: string | null = null;

    public dateOfBirth: string | null = null;

    public email: string | null = null;

    public emailBusiness: string | null = null;

    public emailCorrespondence: string | null = null;

    public emailInvoice: string | null = null;

    public emailLogin: string | null = null;

    public fullName: string | null = null;

    public gender: string | null = null;

    public houseNumber: string | null = null;

    public houseNumberPostfix: string | null = null;

    public id: number | null = null;

    public initials: string | null = null;

    public invoicePreferenceAddress: string | null = null;

    public isRegistration: boolean | null = false;

    public kNumber: string | null = null;

    public firstName: string | null = null;

    public lastName: string | null = null;

    public mandatoryMailOnly: boolean | null = false;

    public middleName: string | null = null;

    public navigation: Navigation | null = null;

    public noAnnualInvoice: boolean | null = false;

    public phone: string | null = null;

    public phoneMobile: string | null = null;

    public placeOfBirth: string | null = null;

    public postal: string | null = null;

    public postfix: string | null = null;

    public prefix: string | null = null;

    public province: string | null = null;

    public registrationTypes: ReadRegistrationTypeDto[] = [];

    public street: string | null = null;

    public remarks: Remark[] = [];

    public tradeOrganisations: TradeOrganisation[] = [];

    public user: User | null = null;

    public vNumber: string | null = null;

    public getActive(): string {
        return this.active ? 'Ja' : 'Nee';
    }

    public getDateOfBirth(): string {
        return DateFormatUtils.dateFormatToDisplay(this?.dateOfBirth?.split(' ')[0] ?? '');
    }

    public getFullName(): string {
        let name = '';
        name += (this.initials ? `${this.initials} ` : '');
        name += (this.middleName ? `${this.middleName} ` : '');
        name += (this.lastName ? `${this.lastName}` : '');

        return name;
    }

    public getFullNameAndTitles(): string {
        let name = '';
        name += (this.prefix ? `${this.prefix} ` : '');
        name += this.getFullName();
        name += (this.postfix ? ` ${this.postfix} ` : '');

        return name;
    }

    public getGender(): string {
        if (this.gender === 'm') {
            return 'De heer';
        }

        if (this.gender === 'f') {
            return 'Mevrouw';
        }

        return '';
    }

    public getMandatoryMailOnly(): string {
        return this.mandatoryMailOnly ? 'Ja' : 'Nee';
    }

    /* eslint-disable-next-line complexity */
    public determineAddress(): ReadAddressDto | null {
        if (this.invoicePreferenceAddress === AddressTypeEnum.PERSONAL) {
            return {
                city: this.city ?? '',
                houseNumber: this.houseNumber ?? '',
                houseNumberPostfix: this.houseNumberPostfix ?? '',
                postal: this.postal ?? '',
                salutation: this.getFullNameAndTitles(),
                street: this.street ?? '',
            };
        } else if (
            this.invoicePreferenceAddress === AddressTypeEnum.COMPANY &&
            this.companies.length > 0
        ) {
            return {
                city: this.companies[0].city ?? '',
                houseNumber: this.companies[0].houseNumber ?? '',
                houseNumberPostfix: this.companies[0].houseNumberPostfix ?? '',
                organisation: this.companies[0].name ?? '',
                postal: this.companies[0].postal ?? '',
                salutation: this.getFullNameAndTitles(),
                street: this.companies[0].street ?? '',
            };
        }

        return null;
    }

    public getRegistrationTypes(): string {
        if (this.registrationTypes.length > 0) {
            return this.registrationTypes
                .map(registrationType => registrationType.type)
                .join(', ');
        }

        return '';
    }

    public getRelevantNumbers(): Record<string, string | null> {
        const relevantNumbers: { [key: string]: string | null } = {};
        if (this.registrationTypes ?? false) {
            for (const [, registrationType] of Object.entries(this.registrationTypes)) {
                relevantNumbers[`${registrationType.uniqueIdentifier}`] = registrationType.number;
            }
        }

        return relevantNumbers;
    }

    public getNumberTypeLabel(): string {
        if (this.aNumber) {
            return 'A-nummer';
        }

        if (this.kNumber) {
            return 'K-nummer';
        }

        return 'V-nummer';
    }

    public getNumberTypeValue(): string {
        if (this.vNumber) {
            return this.vNumber;
        }

        if (this.aNumber) {
            return this.aNumber;
        }

        if (this.kNumber) {
            return this.kNumber;
        }

        return this.vNumber ?? '';
    }

    public isRelationRM(): boolean {
        if (this.registrationTypes.length > 0) {
            return this.registrationTypes.some(registrationType => registrationType.type === 'RM');
        }

        return false;
    }

    public isRelationARMT(): boolean {
        if (this.registrationTypes.length > 0) {
            return this.registrationTypes.some(registrationType => registrationType.type === 'A-RMT');
        }

        return false;
    }

    public isRelationKRMT(): boolean {
        if (this.registrationTypes.length > 0) {
            return this.registrationTypes.some(registrationType => registrationType.type === 'K-RMT');
        }

        return false;
    }
}
