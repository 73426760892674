/* eslint-disable unicorn/filename-case, max-lines */

import AttachmentService from '~/services/api/AttachmentService';
import CertificateService from '~/services/api/CertificateService';
import ChamberService from '~/services/api/ChamberService';
import CompanyService from '~/services/api/CompanyService';
import CourseService from '~/services/api/CourseService';
import ExtranetAttachmentService from '~/services/api/extranet/AttachmentService';
import ExtranetCertificateService from '~/services/api/extranet/CertificateService';
import ExtranetCompanyService from '~/services/api/extranet/CompanyService';
import ExtranetCourseService from '~/services/api/extranet/CourseService';
import ExtranetDocumentService from '~/services/api/extranet/DocumentService';
import ExtranetInvoiceService from '~/services/api/extranet/InvoiceService';
import InvoiceService from '~/services/api/InvoiceService';
import LevelOfMeasurementsStatusTextService from '~/services/api/LevelOfMeasurementsStatusTextService';
import ProductService from '~/services/api/ProductService';
import RelationService from '~/services/api/RelationService';
import ReportService from '~/services/api/ReportService';
import TaxRateService from '~/services/api/TaxRateService';
import UnlinkableMeasurementService from '~/services/api/UnlinkableMeasurementService';
import UserService from '~/services/api/UserService';
import MailMessageService from '~/services/api/MailMessageService';
import DocumentService from '~/services/api/DocumentService';
import ExactService from '~/services/api/ExactService';
import ExtranetNotificationService from '~/services/api/ExtranetNotificationService';
import SignupService from '~/services/api/SignupService';
import type { UnlinkableMeasurementTypeEnum } from '~/enums/UnlinkableMeasurementTypeEnum';
import RegistrationTypeService from '~/services/api/RegistrationTypeService';

type QueryParameters = {
    queryParameters: Record<string, unknown>;
};

const FetchActions = {
    forDataGridCertificatesExpiringThisYearOverview({ queryParameters }: QueryParameters) {
        const service = new CertificateService();

        return service.fetchCertificatesEligibleForExtension(queryParameters);
    },
    forDataGridCertificatesOverview({ queryParameters }: QueryParameters) {
        const service = new CertificateService();

        return service.fetchCertificates(queryParameters);
    },
    forDataGridCertificatesViewAttachments({ parameters, queryParameters }: QueryParameters & {
        parameters: {
            certificateId: number;
        };
    }) {
        const { certificateId } = parameters;

        const service = new AttachmentService();

        return service.fetchAttachments(certificateId, CertificateService.basePath, queryParameters);
    },
    forDataGridCertificatesViewOtherCertificates({ parameters, queryParameters }: QueryParameters & {
        parameters: {
            certificateId: number;
        };
    }) {
        const { certificateId } = parameters;

        const service = new CertificateService();

        return service.fetchOtherCertificates(certificateId, queryParameters);
    },
    forDataGridChambersOverview({ queryParameters }: QueryParameters) {
        const service = new ChamberService();

        return service.fetchChambers(queryParameters);
    },
    forDataGridCompaniesOverview({ queryParameters }: QueryParameters) {
        const service = new CompanyService();

        return service.fetchCompanies(queryParameters);
    },
    forDataGridCompaniesViewAttachments({ parameters, queryParameters }: QueryParameters & {
        parameters: {
            companyId: number;
        };
    }) {
        const { companyId } = parameters;
        const service = new AttachmentService();

        return service.fetchAttachments(companyId, CompanyService.basePath, queryParameters);
    },
    forDataGridCompaniesViewRelations({ parameters, queryParameters }: QueryParameters & {
        parameters: {
            companyId: number;
        };
    }) {
        const { companyId } = parameters;
        const service = new CompanyService();

        return service.fetchLinkedRelations(companyId, queryParameters);
    },
    forDataGridCoursesOverview({ queryParameters }: QueryParameters) {
        const service = new CourseService();
        const filter = {
            orderBy: 'created_at',
            sortDesc: 1,
        };

        return service.fetchCourses({
            ...filter,
            ...queryParameters,
        });
    },
    forDataGridDocumentsOverview({ queryParameters }: QueryParameters) {
        const service = new DocumentService();

        return service.fetchDocuments(queryParameters);
    },
    forDataGridExactLogsOverview({ queryParameters }: QueryParameters) {
        const service = new ExactService();

        return service.fetchLogs(queryParameters);
    },
    forDataGridExtranetAttachmentsOverview({ queryParameters }: QueryParameters) {
        const service = new ExtranetAttachmentService();

        return service.fetchAttachments(queryParameters);
    },
    forDataGridExtranetCertificatesOverview({ queryParameters }: QueryParameters) {
        const service = new ExtranetCertificateService();

        return service.fetchCertificates(queryParameters);
    },
    forDataGridExtranetCompaniesOverview({ queryParameters }: QueryParameters) {
        const service = new ExtranetCompanyService();

        return service.fetchCompanies(queryParameters);
    },
    forDataGridExtranetCoursesOverview({ queryParameters }: QueryParameters) {
        const service = new ExtranetCourseService();

        return service.fetchCourses(queryParameters);
    },
    forDataGridExtranetDocumentsOverview({ queryParameters }: QueryParameters) {
        const service = new ExtranetDocumentService();

        return service.fetchDocuments(queryParameters);
    },
    forDataGridExtranetInvoicesOverview({ queryParameters }: QueryParameters) {
        const service = new ExtranetInvoiceService();

        return service.fetchInvoices(queryParameters);
    },
    forDataGridExtranetNotificationsOverview({ queryParameters }: QueryParameters) {
        const service = new ExtranetNotificationService(queryParameters);

        return service.fetchExtranetNotifications();
    },
    forDataGridExtranetRegistrationsOverview({ queryParameters }: QueryParameters) {
        const service = new ExtranetCertificateService();

        return service.fetchRegistrations(queryParameters);
    },
    forDataGridInvoicesExactExport({ queryParameters }: QueryParameters) {
        const service = new InvoiceService();

        return service.fetchInvoices(queryParameters);
    },
    forDataGridInvoicesOverview({ queryParameters }: QueryParameters) {
        const service = new InvoiceService();

        return service.fetchInvoices(queryParameters);
    },
    forDataGridInvoicesOverviewDeleted({ queryParameters }: QueryParameters) {
        const service = new InvoiceService();

        return service.fetchInvoices(queryParameters, 'deleted-invoices');
    },
    forDataGridInvoicesOverviewOpen({ queryParameters }: QueryParameters) {
        const service = new InvoiceService();

        return service.fetchInvoices(queryParameters, 'open-invoices');
    },
    forDataGridInvoicesOverviewPaid({ queryParameters }: QueryParameters) {
        const service = new InvoiceService();

        return service.fetchInvoices(queryParameters, 'paid-invoices');
    },
    forDataGridInvoicesOverviewUnpaid({ queryParameters }: QueryParameters) {
        const service = new InvoiceService();

        return service.fetchInvoices(queryParameters, 'unpaid-invoices');
    },
    forDataGridInvoicesSendOpen({ queryParameters }: QueryParameters) {
        const service = new InvoiceService();

        return service.fetchInvoices(queryParameters, 'open-invoices-not-queued');
    },
    forDataGridInvoicesSendReminder({ queryParameters }: QueryParameters) {
        const service = new InvoiceService();

        return service.fetchInvoices(queryParameters, 'unpaid-invoices-not-queued');
    },
    forDataGridLevelOfMeasurementsOverviewStatusTexts({ queryParameters }: QueryParameters) {
        const service = new LevelOfMeasurementsStatusTextService();

        return service.fetchStatusTexts(queryParameters);
    },
    forDataGridMailMessagesOverview({ queryParameters }: QueryParameters) {
        const service = new MailMessageService();

        return service.fetchMailMessages(queryParameters);
    },
    forDataGridProductsOverview({ queryParameters }: QueryParameters) {
        const service = new ProductService();

        return service.fetchProducts(queryParameters);
    },
    forDataGridRegistrationTypesOverview({ queryParameters }: QueryParameters) {
        const service = new RegistrationTypeService();

        return service.fetchRegistrationTypes(queryParameters);
    },
    forDataGridRegistrationsOverview({ queryParameters }: QueryParameters) {
        const service = new CertificateService();

        return service.fetchRegistrations(queryParameters);
    },
    forDataGridRelationsOverview({ queryParameters }: QueryParameters) {
        const service = new RelationService();

        return service.fetchRelations(queryParameters);
    },
    forDataGridRelationsOverviewAnnualInvoices({ queryParameters }: QueryParameters) {
        const service = new InvoiceService();

        return service.fetchOverviewAnnualInvoices(queryParameters);
    },
    forDataGridRelationsViewAttachments({ parameters, queryParameters }: QueryParameters & {
        parameters: {
            relationId: number;
        };
    }) {
        const { relationId } = parameters;
        const service = new AttachmentService();

        return service.fetchAttachments(relationId, RelationService.basePath, queryParameters);
    },
    forDataGridRelationsViewCertificates({ parameters, queryParameters }: QueryParameters & {
        parameters: {
            relationId: number;
        };
    }) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedCertificates(relationId, queryParameters);
    },
    forDataGridRelationsViewCompanies({ parameters, queryParameters }: QueryParameters & {
        parameters: {
            relationId: number;
        };
    }) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedCompanies(relationId, queryParameters);
    },
    forDataGridRelationsViewCourses({ parameters }: {
        parameters: {
            relationId: number;
        };
    }) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedCourses(relationId);
    },
    forDataGridRelationsViewInvoices({ parameters, queryParameters }: QueryParameters & {
        parameters: {
            relationId: number;
        };
    }) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedInvoices(relationId, queryParameters);
    },
    forDataGridRelationsViewRegistrations({ parameters }: {
        parameters: {
            relationId: number;
        };
    }) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedRegistrations(relationId);
    },
    forDataGridRelationsViewRemarks({ parameters }: {
        parameters: {
            relationId: number;
        };
    }) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedRemarks(relationId);
    },
    forDataGridReportsOverview({ queryParameters }: QueryParameters) {
        const service = new ReportService();

        return service.fetchReports(queryParameters);
    },
    forDataGridSignupsOverview({ queryParameters }: QueryParameters) {
        const service = new SignupService();

        return service.fetchSignups(queryParameters);
    },
    forDataGridSignupsPending({ queryParameters }: QueryParameters) {
        const service = new SignupService();

        return service.fetchPendingSignups(queryParameters);
    },
    forDataGridTaxRatesOverview({ queryParameters }: QueryParameters) {
        const service = new TaxRateService();

        return service.fetchTaxRates(queryParameters);
    },
    forDataGridUnlinkableMeasurementsOverview({ parameters, queryParameters }: QueryParameters & {
        parameters: {
            type: UnlinkableMeasurementTypeEnum;
        };
    }) {
        const { type } = parameters;

        const service = new UnlinkableMeasurementService();

        return service.fetchUnlinkableMeasurements(type, queryParameters);
    },
    forDataGridUsersOverview({ queryParameters }: QueryParameters) {
        const service = new UserService();

        return service.fetchUsers(queryParameters);
    },
};

export default FetchActions;
