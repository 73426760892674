import payload_plugin_bvhGwJhrsb from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/@pinia+nuxt@0.9.0_@rollup+wasm-node@4.24.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_xSbRYQIUuO from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@rollup+wasm-node@4.24.0_@types+node@22.10.7_db0@0.2.1_e_ub6r4bn5qkwl4dkkfqaciembea/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_bg5UAsQklP from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@rollup+wasm-node@4.24.0_@types+node@22.10.7_db0@0.2.1_e_ub6r4bn5qkwl4dkkfqaciembea/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_lYO6aGGthf from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@rollup+wasm-node@4.24.0_@types+node@22.10.7_db0@0.2.1_e_ub6r4bn5qkwl4dkkfqaciembea/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _09_vuex_pinia_dispatch_backwards_compatibility_plugin_QtsS8mwe4r from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/09.vuex-pinia-dispatch-backwards-compatibility-plugin.ts";
import payload_client_gCitH6cMxA from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@rollup+wasm-node@4.24.0_@types+node@22.10.7_db0@0.2.1_e_ub6r4bn5qkwl4dkkfqaciembea/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_jtqU4aAT4Z from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@rollup+wasm-node@4.24.0_@types+node@22.10.7_db0@0.2.1_e_ub6r4bn5qkwl4dkkfqaciembea/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_cQOuh4oU57 from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@rollup+wasm-node@4.24.0_@types+node@22.10.7_db0@0.2.1_e_ub6r4bn5qkwl4dkkfqaciembea/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Pmz6lapn47 from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@rollup+wasm-node@4.24.0_@types+node@22.10.7_db0@0.2.1_e_ub6r4bn5qkwl4dkkfqaciembea/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_xrJMKRZKSi from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/@pinia+nuxt@0.9.0_@rollup+wasm-node@4.24.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/local/jenkins/workspace/appvastgoedcertnl/.nuxt/components.plugin.mjs";
import prefetch_client_P7aFWa6EkC from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@rollup+wasm-node@4.24.0_@types+node@22.10.7_db0@0.2.1_e_ub6r4bn5qkwl4dkkfqaciembea/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/usr/local/jenkins/workspace/appvastgoedcertnl/.nuxt/floating-vue.mjs";
import plugin_BHxsXp8CHU from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.4_@rollup+wasm-node@4.24.0_magicast@0.3.5_next-auth@4.21.1_next@13.5._hmikjz473aj7qsr4iweip4rpwy/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import sentry_client_qu71Bk6IhI from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.30.1_@opentelemetry+api@1._pnut53phke76shotqt2itdp4hi/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/usr/local/jenkins/workspace/appvastgoedcertnl/.nuxt/sentry-client-config.mjs";
import _01_backend_fetch_plugin_yW8q2GdTHp from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/01.backend-fetch-plugin.ts";
import _02_auth_can_not_directive_plugin_A0Cx9k3g5u from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/02.auth-can-not-directive-plugin.ts";
import _03_auth_can_directive_plugin_0j38mA4Ik6 from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/03.auth-can-directive-plugin.ts";
import _04_route_namespace_mixin_plugin_OTju9iF4LS from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/04.route-namespace-mixin-plugin.ts";
import _05_provide_saveurl_plugin_qQD6sRPhal from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/05.provide-saveurl-plugin.ts";
import _06_nuxt_fetch_hook_backwards_compatibility_plugin_uPCNGOQbXz from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/06.nuxt-fetch-hook-backwards-compatibility-plugin.ts";
import _07_subscribe_data_grid_filters_changed_pinia_plugin_p2NWCvhmIz from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/07.subscribe-data-grid-filters-changed-pinia-plugin.ts";
import _08_form_paths_mixin_plugin_4NdMNYmQQP from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/08.form-paths-mixin-plugin.ts";
export default [
  payload_plugin_bvhGwJhrsb,
  revive_payload_client_xSbRYQIUuO,
  unhead_bg5UAsQklP,
  router_lYO6aGGthf,
  _09_vuex_pinia_dispatch_backwards_compatibility_plugin_QtsS8mwe4r,
  payload_client_gCitH6cMxA,
  navigation_repaint_client_jtqU4aAT4Z,
  check_outdated_build_client_cQOuh4oU57,
  chunk_reload_client_Pmz6lapn47,
  plugin_vue3_xrJMKRZKSi,
  components_plugin_KR1HBZs4kY,
  prefetch_client_P7aFWa6EkC,
  floating_vue_EIcJ7xiw0h,
  plugin_BHxsXp8CHU,
  sentry_client_qu71Bk6IhI,
  sentry_client_config_o34nk2sJbg,
  _01_backend_fetch_plugin_yW8q2GdTHp,
  _02_auth_can_not_directive_plugin_A0Cx9k3g5u,
  _03_auth_can_directive_plugin_0j38mA4Ik6,
  _04_route_namespace_mixin_plugin_OTju9iF4LS,
  _05_provide_saveurl_plugin_qQD6sRPhal,
  _06_nuxt_fetch_hook_backwards_compatibility_plugin_uPCNGOQbXz,
  _07_subscribe_data_grid_filters_changed_pinia_plugin_p2NWCvhmIz,
  _08_form_paths_mixin_plugin_4NdMNYmQQP
]