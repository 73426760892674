import type { Attachment } from './Attachment';
import type { CertificateState } from './CertificateState';
import type { Chamber } from './Chamber';
import type { Relation } from './Relation';
import type { Remark } from '~/models/Remark';
import DateFormatUtils from '~/utils/DateFormatUtils';
import type { SubscriptionPeriod } from '~/models/SubscriptionPeriod';

export type CertificateType = 'subscription' | 'registration';

export class Certificate {
    public attachments: Attachment[] = [];

    public certificateNumber: string | null = null;

    public certificateState: CertificateState | null = null;

    public chamber?: Chamber | null = null;

    public chamberCode?: string | null = null;

    public endDate: string | null = null;

    public fullStatus: string | null = null;

    public id: number | null = null;

    public issuer?: string | null = null;

    public issuerStatus: string | null = null;

    public pointsAchieved: number | null = null;

    public pointsRequired: number | null = null;

    public relation: Relation | null = null;

    public relationName: string | null = null;

    public showPoints: boolean | null = null;

    public startDate: string | null = null;

    public status: string | null = null;

    public type: CertificateType | null = null;

    public periods: SubscriptionPeriod[] | null = null;

    public remarks: Remark[] = [];

    public getEndDate(): string {
        return this.endDate ? DateFormatUtils.dateFormatToDisplay(this.endDate) : '';
    }

    public getStartDate(): string {
        return this.startDate ? DateFormatUtils.dateFormatToDisplay(this.startDate) : '';
    }

    get isSubscription(): boolean {
        return this.type === 'subscription';
    }
}
