import {
    ADD_WRITE_INVOICE_INVOICE_LINE,
    SET_ADDRESSES,
    SET_INVOICE,
    SET_WRITE_INVOICE,
    SET_WRITE_INVOICE_ACCOUNTING_PERIOD,
    SET_WRITE_INVOICE_ACTION,
    SET_WRITE_INVOICE_ADDRESS,
    SET_WRITE_INVOICE_ADDRESS_TYPE,
    SET_WRITE_INVOICE_DATE,
    SET_WRITE_INVOICE_DESCRIPTION,
    SET_WRITE_INVOICE_INVOICE_LINES_AMOUNT,
    SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT,
    SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_CODE,
    SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_ID,
    SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_NAME,
    SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_PRICE,
    SET_WRITE_INVOICE_INVOICE_LINES_SUBTOTAL,
    SET_WRITE_INVOICE_INVOICE_LINES_TAX_RATE,
    SET_WRITE_INVOICE_INVOICE_STATUS_ID,
    SET_WRITE_INVOICE_RELATION,
    SET_WRITE_INVOICE_SEND_DATE,
    SET_WRITE_INVOICE_SUBTOTAL,
    SET_WRITE_INVOICE_TAX_AMOUNT,
    SET_WRITE_INVOICE_TOTAL,
    UNSET_WRITE_INVOICE_INVOICE_LINE,
} from './-mutation-types';
import { useInvoicesStore } from '~/stores/invoices';
import type { Invoice } from '~/models/Invoice';
import type { WriteInvoiceDto } from '~/types/Invoice';
import type { RelationAddress } from '~/models/RelationAddress';
import type { AddressTypeEnum } from '~/enums/AddressTypeEnum';
import type { InvoiceActionEnum } from '~/enums/InvoiceActionEnum';
import { defaultInvoiceLineData } from '~/stores/invoices/state';
import type { Product } from '~/models/Product';

const Mutations = {
    [SET_ADDRESSES](payload: RelationAddress | null) {
        const invoicesStore = useInvoicesStore();
        invoicesStore.addresses = payload;
    },
    [SET_INVOICE](payload: Invoice | null) {
        const invoicesStore = useInvoicesStore();
        invoicesStore.invoice = payload;
    },
    [SET_WRITE_INVOICE](payload: WriteInvoiceDto | null) {
        const invoicesStore = useInvoicesStore();
        invoicesStore.writeInvoice = payload;
    },
    [SET_WRITE_INVOICE_ACCOUNTING_PERIOD](payload: number) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.accountingPeriod = payload;
        }
    },
    [SET_WRITE_INVOICE_ACTION](payload: InvoiceActionEnum) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.action = payload;
        }
    },
    [SET_WRITE_INVOICE_ADDRESS](payload: string) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.address = payload;
        }
    },
    [SET_WRITE_INVOICE_ADDRESS_TYPE](payload: AddressTypeEnum) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.addressType = payload;
        }
    },
    [SET_WRITE_INVOICE_DATE](payload: string) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.date = payload;
        }
    },
    [SET_WRITE_INVOICE_DESCRIPTION](payload: string) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.description = payload;
        }
    },
    [SET_WRITE_INVOICE_INVOICE_STATUS_ID](payload: number) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.invoiceStatusId = payload;
        }
    },
    [SET_WRITE_INVOICE_RELATION](payload: number) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.relationId = payload;
        }
    },
    [SET_WRITE_INVOICE_SEND_DATE](payload: string) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.sendDate = payload;
        }
    },
    [SET_WRITE_INVOICE_SUBTOTAL](payload: number) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.subtotal = payload;
        }
    },
    [SET_WRITE_INVOICE_TAX_AMOUNT](payload: number) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.taxAmount = payload;
        }
    },
    [SET_WRITE_INVOICE_TOTAL](payload: number) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.total = payload;
        }
    },

    // Invoice line setters
    // eslint-disable-next-line vue/sort-keys
    [ADD_WRITE_INVOICE_INVOICE_LINE]() {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.invoiceLines.push(defaultInvoiceLineData());
        }
    },
    [SET_WRITE_INVOICE_INVOICE_LINES_AMOUNT]({ index, value }: {
        index: number;
        value: number;
    }) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.invoiceLines[index].amount = value;
        }
    },
    [SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT]({ index, value }: {
        index: number;
        value: Product;
    }) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.invoiceLines[index].product = value;
        }
    },
    [SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_CODE]({ index, value }: {
        index: number;
        value: string;
    }) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.invoiceLines[index].productCode = value;
        }
    },
    [SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_ID]({ index, value }: {
        index: number;
        value: number;
    }) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.invoiceLines[index].productId = value;
        }
    },
    [SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_NAME]({ index, value }: {
        index: number;
        value: string;
    }) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.invoiceLines[index].productName = value;
        }
    },
    [SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_PRICE]({ index, value }: {
        index: number;
        value: number;
    }) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.invoiceLines[index].productPrice = value;
        }
    },
    [SET_WRITE_INVOICE_INVOICE_LINES_SUBTOTAL]({ index, value }: {
        index: number;
        value: number;
    }) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.invoiceLines[index].subtotal = value;
        }
    },
    [SET_WRITE_INVOICE_INVOICE_LINES_TAX_RATE]({ index, value }: {
        index: number;
        value: number;
    }) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice) {
            invoicesStore.writeInvoice.invoiceLines[index].taxRate = value;
        }
    },
    [UNSET_WRITE_INVOICE_INVOICE_LINE](uid: string) {
        const invoicesStore = useInvoicesStore();
        if (invoicesStore.writeInvoice && invoicesStore.writeInvoice.invoiceLines.length > 1) {
            invoicesStore.writeInvoice.invoiceLines = invoicesStore.writeInvoice.invoiceLines
                .filter(invoiceLine => invoiceLine.uid !== uid);
        }
    },
};

export default Mutations;
