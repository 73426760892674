import type { MailMessage } from '~/models/MailMessage';
import type { PreviewMailMessage } from '~/models/PreviewMailMessage';

export type MailMessageState = {
    mailMessage: MailMessage | PreviewMailMessage | null;
    mailMessages: MailMessage[];
};

export const defaultStateData = (): MailMessageState => ({
    mailMessage: null,
    mailMessages: [],
});

export const DefaultState = (): MailMessageState => defaultStateData();

export type State = ReturnType<typeof DefaultState>;
