import { nanoid } from 'nanoid';
import { AddressTypeEnum } from '~/enums/AddressTypeEnum';
import { InvoiceActionEnum } from '~/enums/InvoiceActionEnum';
import type { Invoice } from '~/models/Invoice';
import type { RelationAddress } from '~/models/RelationAddress';
import type { WriteInvoiceDto } from '~/types/Invoice';
import type { WriteInvoiceLineDto } from '~/types/InvoiceLine';
import DateFormatUtils from '~/utils/DateFormatUtils';

export type InvoiceState = {
    invoice: Invoice | null;
    writeInvoice: WriteInvoiceDto | null;
    addresses: RelationAddress | null;
};

export const defaultInvoiceLineData = (): WriteInvoiceLineDto => ({
    amount: 1,
    id: null,
    product: null,
    productCode: '',
    productId: 0,
    productName: '',
    productPrice: 0,
    subtotal: 0,
    taxRate: 0,
    uid: nanoid(),
});

export const defaultWriteInvoiceData = (): WriteInvoiceDto => ({
    accountingPeriod: new Date().getFullYear(),
    action: InvoiceActionEnum.SAVE,
    address: '',
    addressType: AddressTypeEnum.COMPANY,
    date: DateFormatUtils.dateFormatToData((new Date()).toLocaleDateString('nl-NL')),
    description: '',
    id: null,
    invoiceLines: [
        defaultInvoiceLineData(),
    ],
    invoiceStatusId: null,
    relationId: 0,
    sendDate: null,
    subtotal: 0,
    taxAmount: 0,
    total: 0,
});

export const defaultStateData = (): InvoiceState => ({
    addresses: null,
    invoice: null,
    writeInvoice: defaultWriteInvoiceData(),
});

export const DefaultState = (): InvoiceState => defaultStateData();

export type State = ReturnType<typeof DefaultState>;
