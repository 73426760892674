import type { Course } from '~/models/Course';

export type CourseState = {
    course: Course | null;
};

export const defaultStateData = (): CourseState => ({
    course: null,
});

export const DefaultState = (): CourseState => defaultStateData();

export type State = ReturnType<typeof DefaultState>;
