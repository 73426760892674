import DateFormatUtils from '~/utils/DateFormatUtils';

export class Remark {
    public createdAt: string | null = null;

    public description: string | null = null;

    public id: number | null = null;

    public important = 0;

    public typeId: number | null = null;

    public updatedAt: string | null = null;

    public user: string | null = null;

    public getCreationDate(): string {
        return DateFormatUtils.dateFormatToDisplay(this?.createdAt?.split(' ')[0] ?? '');
    }
}
