export const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);
export const camelToSnake = (string: string): string => string.replaceAll(
    /[A-Z]/g,
    (letter): string => `_${letter.toLowerCase()}`,
);
export const kebabToSnake = (string: string): string => string.replaceAll('-', '_');
export const snakeToCamel = (string: string): string => string.replaceAll(/([_-]\w)/g, g => g[1].toUpperCase());
export const snakeToKebab = (string: string): string => string.replaceAll('_', '-');
export const snakeToPascal = (string: string): string => {
    const camelCase = snakeToCamel(string);
    const pascalCase = camelCase[0].toUpperCase() + camelCase.slice(1);

    return pascalCase;
};

