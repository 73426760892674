import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type { Course } from '~/models/Course';
import type {
    CourseResponse,
    CoursesOverview,
    CoursesOverviewResponse,
    WriteCourseBijZijnDto,
    WriteCourseChambersDto,
    WriteCourseCrmDto,
    WritePointsPerRegistrationTypeDto,
} from '~/types/Course';
import { CourseFactory } from '~/models/factories/CourseFactory';
import type { List } from '~/models/List';
import type { ListResponse } from '~/types/List';
import { ListFactory } from '~/models/factories/ListFactory';

export default class CourseService extends BaseApiService {
    private basePath = 'courses';

    /**
     * @description Fetch single course by course id.
     * @param {number} courseId Identifier of the course to fetch
     * @returns {Promise<Course>} Promise with the Course model as payload
     */
    async fetchCourse(courseId: number): Promise<Course> {
        try {
            const response = await this.baseGet<CourseResponse>(
                `${this.createPath(this.basePath)}/${courseId}`,
            );

            return (new CourseFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple courses depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<CoursesOverview>} Promise with the CoursesOverview as payload
     */
    async fetchCourses(queryParameters: (Record<string, unknown> | null) = null): Promise<CoursesOverview> {
        try {
            const response = await this.baseGet<CoursesOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new CourseFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of approved courses.
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchApprovedList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(this.basePath)}/approved/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a course.
     * @param {number} courseId Id of the resource to save
     * @param {WriteCourseBijZijnDto | WriteCourseCrmDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(courseId: number, data: WriteCourseBijZijnDto | WriteCourseCrmDto): Promise<null> {
        try {
            return this.basePatch<null>(
                `${this.createPath(this.basePath)}/${courseId}`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a course's linked chambers.
     * @param {number} courseId Id of the course to save to chambers of
     * @param {WriteCourseChambersDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    updateCourseChambers(courseId: number, data: WriteCourseChambersDto): Promise<null> {
        try {
            return this.basePut<null>(
                `${this.createPath(this.basePath)}/${courseId}/chambers`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Toggle approved state.
     * @param {number} courseId Identifier of the course to toggle the approved state of
     * @returns {Promise<null>} Promise with an empty payload
     */
    toggleApproved(courseId: number): Promise<null> {
        try {
            return this.basePatch<null>(
                `${this.createPath(this.basePath)}/${courseId}/toggleApproved`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    setRegistrationTypePoints(
        courseId: number,
        pointsPerRegistrationType: WritePointsPerRegistrationTypeDto,
    ): Promise<null> {
        // I don't prefer this way of transforming the data,
        // but the source object's structure makes it easier to dynamically bind the model with individual types.
        const registrationTypes = Object.keys(pointsPerRegistrationType).map(key => ({
            id: Number.parseInt(key, 10),
            points: pointsPerRegistrationType[Number.parseInt(key, 10)],
        }));

        try {
            return this.basePut<null>(
                `${this.createPath(this.basePath)}/${courseId}/registration-type-points`,
                {
                    registrationTypes,
                },
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
