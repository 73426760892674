import DateFormatUtils from '~/utils/DateFormatUtils';
import type { UnlinkableMeasurementTypeEnum } from '~/enums/UnlinkableMeasurementTypeEnum';

export class UnlinkableMeasurement {
    public client: string | null = null;

    public content: string | null = null;

    public date: string | null = null;

    public error: string | null = null;

    public id: number | null = null;

    public level: number | null = null;

    public type: UnlinkableMeasurementTypeEnum | null = null;

    public getDate(): string {
        return DateFormatUtils.dateFormatToDisplay(this?.date?.split(' ')[0] ?? '');
    }

    public formattedContent(): { [key: string]: string | number } {
        return JSON.parse(this.content || '');
    }
}
